import axios from "axios";
import {
  IRequestRegisterUser,
  IRequestRegisterUserConfirm,
} from "@/api/types/registerUser.types";
import { ENV_CONSTANTS } from "@/common/constants/env.const";

export default class RegisterUserAPI {
  private static baseUrl = "user/registration";

  public static async RequestRegisterUser(data: IRequestRegisterUser) {
    return await axios.post(`/${this.baseUrl}`, data);
  }

  public static async RequestRegisterUserConfirm(
    secureToken: IRequestRegisterUserConfirm,
  ) {
    return await axios.post(
      `${ENV_CONSTANTS.API_BASEURL}/${this.baseUrl}/confirmation`,
      secureToken,
      {
        headers: {
          Authorization: `Bearer ${ENV_CONSTANTS.DEFAULT_API_BEARER_TOKEN}`,
          "Content-Type": "application/json",
        },
      },
    );
  }
}
