import axios from "axios";
import {
  IRequestUserPasswordReset,
  IRequestUserProfileUpdate,
  IRequestUserConfirmPasswordReset,
} from "@/api/types/loginUser.types";
import { plans } from "@/common/constants/pricingConstants";
import heapUserInternalTracking, {
  heapAddEventProperties,
  heapAddUserProperty,
  heapClearEventProperty,
  heapIdentify,
} from "@/common/utils/heapTracking";

type HeapProperty = {
  email: string;
  stripe_customer_id?: string;
  trial_end_date?: string;
  plan?: string;
  has_account?: string;
  opted_in?: boolean;
};

export default class LoginUserAPI {
  private static baseUrl = "/user";

  public static async RequestUserPasswordReset(
    email: IRequestUserPasswordReset,
  ) {
    return await axios.post(`${this.baseUrl}/passwordreset`, email);
  }

  public static async RequestUserProfileUpdate(
    data: IRequestUserProfileUpdate,
  ) {
    return await axios.put(`${this.baseUrl}/profile`, data);
  }

  public static async RequestUserSetNewPassword(
    body: IRequestUserConfirmPasswordReset,
  ) {
    return await axios.post(`${this.baseUrl}/passwordreset/confirmation`, body);
  }

  public static async RequestUserInfo() {
    const response = await axios.get(`${this.baseUrl}/me`);

    const customerKey = response.data?.payment?.customerKey;
    const trial = response.data.plan?.trial;
    const trialEndData = trial?.trialEndDate;
    const planName =
      response.data.plan?.planName === "register-user"
        ? plans.BASIC
        : response.data.plan.planName;
    const email = response.data.userInfo?.email;
    const marketing = response.data.userInfo?.isMarketingOptIn;

    heapIdentify(email);

    const heapProperty: HeapProperty = {
      email,
    };

    if (customerKey) {
      heapProperty.stripe_customer_id = customerKey;
    }

    if (trialEndData) {
      heapProperty.trial_end_date = trialEndData;
    }

    if (!trialEndData && customerKey) {
      heapProperty.trial_end_date = "ended";
    }

    if (planName) {
      heapProperty.plan = planName;
    }

    if (marketing != null) {
      heapProperty.opted_in = marketing;
    }

    heapProperty.has_account = "true";

    heapUserInternalTracking(email);

    heapAddUserProperty(heapProperty);

    heapClearEventProperty();
    heapAddEventProperties({ logged_in: true });
    return response.data;
  }
}
