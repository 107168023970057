import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import RegisterUserAPI from "@/api/services/registerUser.api";

export type RegisterSliceType = Pick<RootState, "register">;

export interface IRegisterSliceState {
  isLoadingSignUp: boolean;
}

const initialState: IRegisterSliceState = {
  isLoadingSignUp: false,
};

export const requestSignUp = createAsyncThunk(
  "requestSignUp",
  async ({
    email,
    password,
    isMarketingOptIn,
  }: {
    email: string;
    password?: string;
    isMarketingOptIn?: boolean;
  }) => {
    const { data } = await RegisterUserAPI.RequestRegisterUser({
      user: { email, isMarketingOptIn },
      password,
    });

    return data;
  },
);

export const confirmSignUp = createAsyncThunk(
  "confirmSignUp",
  async ({ secureToken }: { secureToken: string }) => {
    const { data } = await RegisterUserAPI.RequestRegisterUserConfirm({
      secureToken,
    });

    return data;
  },
);

export const registerSlice = createSlice({
  name: "[registerSlice]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestSignUp.pending, (draftState) => {
      draftState.isLoadingSignUp = true;
    });

    builder.addCase(requestSignUp.fulfilled, (draftState) => {
      draftState.isLoadingSignUp = false;
    });

    builder.addCase(requestSignUp.rejected, (draftState) => {
      draftState.isLoadingSignUp = false;
    });
  },
});

export default registerSlice.reducer;
