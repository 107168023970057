import axios from "axios";
import {
  IRequestReceiverSessionFilesInfo,
  IRequestAnalyseFilesInfo,
} from "../types/receiverUser.types";
import { base64UrlEncode } from "@/common/utils/base64UrlEncoding";
import { ENV_CONSTANTS } from "@/common/constants/env.const";

export default class ReceiverUserAPIs {
  private static baseUrl = "/user/receiver";

  public static async RequestSessionFiles(
    params: IRequestReceiverSessionFilesInfo,
  ) {
    let receiverFilesEndpoint = `${ENV_CONSTANTS.API_BASEURL}${this.baseUrl}/${params.receiverId}/filesetsession/${params.fileSetSessionId}/files`;
    if (params.password && params.password.length > 0) {
      //wtf??
      receiverFilesEndpoint += `?password=${base64UrlEncode(params.password)}`;
    }
    return await axios.get(receiverFilesEndpoint, {
      headers: {
        Authorization: `Bearer ${ENV_CONSTANTS.DEFAULT_API_BEARER_TOKEN}`,
        "Content-Type": "application/json",
      },
    });
  }

  public static async RequestAnalyseFiles(params: IRequestAnalyseFilesInfo) {
    const receiverFilesEndpoint = `${this.baseUrl}/${params.receiverId}/filesetsession/${params.fileSetSessionId}/files`;

    return await axios.post(receiverFilesEndpoint, { files: params.files });
  }
}
