import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export type PricingSliceStateType = Pick<RootState, "pricing">;

export type MarketingFeatures = { name: string };

export type ProductFeature = {
  name: string;
  id: string;
  marketing_features: MarketingFeatures[];
};

export interface IPricingSlice {
  pricePlan: string;
  productFeatures: ProductFeature[];
}

const initialState: IPricingSlice = {
  pricePlan: "",
  productFeatures: [],
};

export const pricingSlice = createSlice({
  name: "[pricingSlice]",
  initialState,
  reducers: {
    setPricePlan(state, action) {
      state.pricePlan = action.payload;
    },

    setProductFeatures(state, action) {
      state.productFeatures = action.payload;
    },
  },
});

export const { setPricePlan, setProductFeatures } = pricingSlice.actions;

export const selectPricePlan = (state: PricingSliceStateType) =>
  state.pricing.pricePlan;

export const selectProductFeatures = (state: PricingSliceStateType) =>
  state.pricing.productFeatures;

export default pricingSlice.reducer;
