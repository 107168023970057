import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import LoginUserAPI from "@/api/services/loginUser.api";

export type LoginSliceType = Pick<RootState, "login">;

export interface ILoginSliceState {
  isLoading: boolean;
  status?: string;
}

const initialState: ILoginSliceState = {
  isLoading: false,
};

export const requestLinkResetPassword = createAsyncThunk(
  "requestLinkResetPassword",
  async ({ email }: { email: string }) => {
    const { data } = await LoginUserAPI.RequestUserPasswordReset({
      email,
    });

    return data;
  },
);

export const requestSetNewPassword = createAsyncThunk(
  "requestSetNewPassword",
  async ({
    secureToken,
    password,
  }: {
    secureToken: string;
    password: string;
  }) => {
    const { data } = await LoginUserAPI.RequestUserSetNewPassword({
      secureToken,
      password,
    });

    return data;
  },
);

export const loginSlice = createSlice({
  name: "[loginSlice]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestLinkResetPassword.pending, (draftState) => {
      draftState.isLoading = true;
    });

    builder.addCase(requestLinkResetPassword.fulfilled, (draftState) => {
      draftState.isLoading = false;
    });

    builder.addCase(requestLinkResetPassword.rejected, (draftState) => {
      draftState.isLoading = false;
    });

    builder.addCase(requestSetNewPassword.pending, (draftState) => {
      draftState.isLoading = true;
    });

    builder.addCase(requestSetNewPassword.fulfilled, (draftState) => {
      draftState.isLoading = false;
    });

    builder.addCase(requestSetNewPassword.rejected, (draftState) => {
      draftState.isLoading = false;
    });
  },
});

export default loginSlice.reducer;
