import { ENV_CONSTANTS } from "@/common/constants/env.const";

export const plans = {
  BASIC: "basic",
  SOLO: "solo",
  PRO: "pro",
  PREMIUM: "premium",
  ENTERPRISE: "enterprise",
};

export type PlanTypes = (typeof plans)[keyof typeof plans];

export const activePlans = ["basic", "solo"];

export const planAvailability = {
  [plans.BASIC]: {
    upgrade: ["solo", "pro", "premium", "enterprise"],
    downgrade: ["none"],
  },
  [plans.SOLO]: {
    upgrade: ["pro", "premium", "enterprise"],
    downgrade: ["basic"],
  },
  [plans.PRO]: {
    upgrade: ["premium", "enterprise"],
    downgrade: ["basic", "pro"],
  },
  [plans.PREMIUM]: {
    upgrade: ["enterprise"],
    downgrade: ["basic", "pro", "premium"],
  },
  [plans.ENTERPRISE]: {
    upgrade: ["none"],
    downgrade: ["basic", "pro", "premium"],
  },
};

export const colorMapper = {
  [plans.BASIC]: "#BDBDBD",
  [plans.SOLO]: "#925EFF",
  [plans.PRO]: "#E87BB1",
  [plans.PREMIUM]: "#269275",
  [plans.ENTERPRISE]: "#26ACFF",
};

export const priceIdMapper = {
  [plans.BASIC]: ENV_CONSTANTS.PRICE_BASIC_ID,
  [plans.SOLO]: ENV_CONSTANTS.PRICE_SOLO_ID,
  [plans.PRO]: ENV_CONSTANTS.PRICE_PRO_ID,
  [plans.PREMIUM]: ENV_CONSTANTS.PRICE_PREMIUM_ID,
  [plans.ENTERPRISE]: ENV_CONSTANTS.PRICE_ENTERPRISE_ID,
};

export const priceMapper = {
  [plans.BASIC]: ENV_CONSTANTS.PRICE_BASIC,
  [plans.SOLO]: ENV_CONSTANTS.PRICE_SOLO,
  [plans.PRO]: ENV_CONSTANTS.PRICE_PRO,
  [plans.PREMIUM]: ENV_CONSTANTS.PRICE_PREMIUM,
  [plans.ENTERPRISE]: ENV_CONSTANTS.PRICE_ENTERPRISE,
};

export const productIdMapper = {
  [plans.BASIC]: ENV_CONSTANTS.PACKAGE_BASIC_ID,
  [plans.SOLO]: ENV_CONSTANTS.PACKAGE_SOLO_ID,
  [plans.PRO]: ENV_CONSTANTS.PACKAGE_PRO_ID,
  [plans.PREMIUM]: ENV_CONSTANTS.PACKAGE_PREMIUM_ID,
  [plans.ENTERPRISE]: ENV_CONSTANTS.PACKAGE_ENTERPRISE_ID,
};

export const activeButtonMapper = {
  [plans.BASIC]: ["solo", "pro", "premium", "enterprise"],
  [plans.SOLO]: ["pro", "premium", "enterprise"],
  [plans.PRO]: ["premium", "enterprise"],
  [plans.PREMIUM]: ["enterprise"],
  [plans.ENTERPRISE]: [],
};

// To avoid type issue
const featuresMapper = {
  [plans.BASIC]: [
    "pricing.plan.basic.features.feature1",
    "pricing.plan.basic.features.feature2",
    "pricing.plan.basic.features.feature3",
    "pricing.plan.basic.features.feature4",
    "pricing.plan.basic.features.feature5",
    "pricing.plan.basic.features.feature6",
  ],
  [plans.PRO]: [
    "pricing.plan.pro.features.feature1",
    "pricing.plan.pro.features.feature2",
    "pricing.plan.pro.features.feature3",
    "pricing.plan.pro.features.feature4",
  ],
  [plans.PREMIUM]: [
    "pricing.plan.premium.features.feature1",
    "pricing.plan.premium.features.feature2",
    "pricing.plan.premium.features.feature3",
    "pricing.plan.premium.features.feature4",
    "pricing.plan.premium.features.feature5",
    "pricing.plan.premium.features.feature6",
    "pricing.plan.premium.features.feature7",
  ],
  [plans.ENTERPRISE]: [
    "pricing.plan.enterprise.features.feature1",
    "pricing.plan.enterprise.features.feature2",
    "pricing.plan.enterprise.features.feature3",
    "pricing.plan.enterprise.features.feature4",
    "pricing.plan.enterprise.features.feature5",
    "pricing.plan.enterprise.features.feature6",
    "pricing.plan.enterprise.features.feature7",
  ],
} as const;

export default featuresMapper;
