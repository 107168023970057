import axios, { AxiosInstance } from "axios";
import { ENV_CONSTANTS } from "@/common/constants/env.const";
import {
  getAuthenticatedUserInfo,
  isRefreshRequired,
  updateToken,
} from "@/auth/auth";
import routes from "@/common/constants/routes";

const excludedPaths = ["auth", "user"];

export function setupAxiosInterceptor(dispatchLogout: () => void) {
  axios.interceptors.request.use(
    (config) => {
      const urlWithoutSlash = config.url?.startsWith(routes.HOME)
        ? config.url?.substring(1)
        : config.url;
      const firstPart = urlWithoutSlash?.split(routes.HOME)[0] ?? "";

      if (excludedPaths.includes(firstPart)) {
        return config;
      }

      if (isRefreshRequired()) {
        updateToken(dispatchLogout);

        const userInfo = getAuthenticatedUserInfo();
        const accessToken = userInfo?.accessToken ?? null;

        // set a new response header "Authorization"
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

let s3UploadInstance: AxiosInstance | null = null;
const MAX_REQUESTS_COUNT = parseInt(ENV_CONSTANTS.MAXIMUM_ACTIVE_UPLOADS);
const INTERVAL_MS = 5000;
let PENDING_REQUESTS = 0;

export function getS3UploadInstance() {
  if (s3UploadInstance !== null) {
    return s3UploadInstance;
  }

  s3UploadInstance = axios.create({});

  s3UploadInstance.interceptors.request.use((config) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++;
          clearInterval(interval);
          resolve(config);
        }
      }, INTERVAL_MS);
    });
  });

  s3UploadInstance.interceptors.response.use(
    function (response) {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
      return Promise.resolve(response);
    },
    function (error) {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
      return Promise.reject(error);
    },
  );

  return s3UploadInstance;
}

export const setAxiosBaseUrl = () => {
  axios.defaults.baseURL = ENV_CONSTANTS.API_BASEURL;
  let token = ENV_CONSTANTS.DEFAULT_API_BEARER_TOKEN;
  if (typeof localStorage !== "undefined") {
    const auth = getAuthenticatedUserInfo();
    token = auth?.accessToken ?? token;
  }

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const setAxiosAccessToken = (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const removeAxiosAccessToken = () => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${ENV_CONSTANTS.DEFAULT_API_BEARER_TOKEN}`;
};
